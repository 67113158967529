// www.kutilek.de Screen CSS V.2.0 by Jens Kutilek, 2002-01-12
// www.kutilek.de Screen CSS V.2.1 by Jens Kutilek, 2004-03-26
// www.kutilek.de Screen CSS V.2.2 by Jens Kutilek, 2005-07-27
// www.kutilek.de Screen CSS V.3.0 by Jens Kutilek, 2013-05-12
// www.kutilek.de Screen CSS V.3.1 by Jens Kutilek, 2015-05-14
// www.kutilek.de Screen CSS V.4.0 by Jens Kutilek, 2024-07-21

// Schriften

@use "webfonts";

@use "colors";

html {
    min-height: 100%;
}

body {
    background-blend-mode: multiply;
    background-image: linear-gradient(
        to bottom,
        hsl(var(--main-bg-color)),
        hsl(var(--bg-horizon-color)) 80%,
        hsl(var(--bg-var-color))
    );
    color: hsl(var(--main-text-color));
    font-family: MyScreenFont, Georgia, serif;
    font-feature-settings: "kern" 1, "liga" 1, "onum" 1, "pnum" 1;
    font-size: 18px;
    hyphens: auto;
    line-height: 1.5em;
    margin: 0;
    padding: 0 0 10em;
    // transitions are not supported for gradients
    // transition: background-image 500ms ease-in-out;
}

nav {
    background-blend-mode: multiply;
    background-color: var(--nav-bg-fallback-color);
    background: linear-gradient(12deg, var(--nav-bg-l-color), var(--nav-bg-r-color)),
        linear-gradient(178deg, oklch(100% 0 142 / 60%) 0%, oklch(80% 0.3 62 / 90%));
    font-family: MyScreenFont;
    font-size: 1em;
    line-height: 2em;
    margin: 0;
    padding: 0.2em 2em 0.3em;

    &.site,
    &.category {
        a {
            border-radius: 0.4em;
            padding: 0.2em 0.4em 0.3em;
            text-decoration: none;
            transition-duration: 300ms;
            transition-property: background-color, color;
            transition-timing-function: ease-in;

            &:link,
            &:visited,
            &:active {
                color: var(--nav-link-color);
            }

            &:hover {
                background-color: var(--nav-link-bg-hover-color);
                color: var(--nav-link-hover-color);
            }
        }

        &.home a.home,
        &.fonts a.fonts,
        &.articles a.articles,
        &.code a.code {
            &:link {
                background-color: var(--nav-link-bg-color);
            }
            &:hover {
                background-color: var(--nav-link-bg-hover-color);
                color: var(--nav-link-hover-color);
            }
        }
    }

    &.category {
        padding-left: 8.475em;
    }

    &.page-nav {
        background: var(--container-bg);
        border-radius: 0.125em;
        float: right;
        font-family: Tahoma, Verdana, sans-serif;
        margin-right: -18em;
        padding: 0;
        position: sticky;
        top: 1em;
        width: 16em;

        h3 {
            font-size: 1em;
            margin: 0.7em 0 0.5em 0.65em;
        }

        ul {
            margin-left: 2em;
            margin-right: 0;
            padding: 0;
        }

        li {
            font-size: 0.9em;
            letter-spacing: 0.04em;
            word-spacing: -0.075em;
        }
    }
}

main {
    background: var(--container-bg);
    border-radius: 0.125em;
    margin: 0 2.3rem;
    max-width: 42em;
    padding: 0.87em 1em 2.5em;

    p,
    ul {
        line-height: 1.5em;
        margin: 0 3.5em 1em 4em;
        max-width: 40em;
    }
}

blockquote {
    background: hsla(var(--bg-var-color) / 10%);
    background-blend-mode: multiply;
    background-image: linear-gradient(
        to bottom,
        hsla(0deg 0% 100% / 86%),
        hsla(0deg 0% 100% / 86%)
    );
    border: 1px solid hsla(var(--bg-var-color) / 20%);
    border-radius: 0.15em;
    margin: 0 3.6em 1em;
    padding: 1.1em 3em 0.75em;
    transition: background 0.5s, border 0.5s;

    p {
        font-family: Tahoma, Verdana, sans-serif;
        font-size: 0.75em;
        letter-spacing: 0.04em;
        margin: 0 0 0.75em;
        word-spacing: -0.05em;

        &.original {
            color: hsl(0deg 0% 30%);
            font-style: italic;
        }
    }
}

.caps {
    font-feature-settings: "c2sc";
    letter-spacing: 0.075em;
    word-spacing: 0.2em;
}

.nh {
    -webkit-hyphens: none;
    hyphens: none;
}

.red {
    color: var(--red-color);
}

h1,
h2,
h3 {
    color: var(--headline-color);
    font-family: MyScreenFont, Verdana, sans-serif;
    font-feature-settings: "ss02" 1;
    font-size: 4em;
    font-weight: 800;
    // letter-spacing: -0.03em;
    line-height: 0.85em;
    margin: 0 0 0.4em;
    max-width: 10em;
    padding: 0;
    word-spacing: 0.075em;
}

h2 {
    font-size: 3em;
    margin-top: 0.8em;
    max-width: 13.33em;
    padding: 0;
}

h3 {
    font-size: 2em;
    margin-bottom: 0.55em;
    margin-top: 1.2em;
    max-width: 20em;
}

tt {
    color: var(--tt-color);
    font-family: Sudo, Consolas, Courier, monospace;
}

li {
    max-width: 38em;
}

ul {
    margin-left: 2.55em;

    &.narrow {
        columns: 3;
    }
}

a {
    margin: 0;
    padding: 0.2em 0;

    &:link,
    &:visited {
        color: var(--link-color);
    }

    &:hover {
        background-color: var(--link-bg-hover);
        color: var(--link-color-hover);
    }

    &:active {
        color: var(--link-color-active);
    }

    &.footnote {
        background: var(--figure-bg);
        border-radius: 0.2em;
        margin-left: 0.25em;
        padding: 0 0.25em;
        text-decoration: none;

        &:hover {
            background: var(--link-color-hover);
            color: var(--link-bg-hover);
        }
    }
}

address {
    font-style: normal;
    font-weight: normal;
}

figure {
    margin: 0 0 1em;
    max-width: 100%;
    padding: 0;

    figcaption {
        font-size: 14px;
        letter-spacing: 0.025em;
        line-height: 1.35rem;
        // max-width: 32em;
        padding: 0.2rem 0 0.5rem;
        word-spacing: 0.1em;
    }

    img {
        background: var(--figure-bg);
        max-width: 100%;
    }

    &.small {
        img {
            max-width: 50%;
        }
    }
}

em {
    font-style: italic;
    letter-spacing: 0.025em;
    word-spacing: -0.05em;
}

.transparent {
    img {
        background: transparent;
    }
}

table {
    border-collapse: collapse;
    margin: 2em 3.6em;
    padding: 0;

    p {
        margin-bottom: 0;
    }

    thead {
        th {
            border-bottom: 0.15em solid #909090;
            border-right: 0;
            padding-left: 1em;

            &:first-child {
                border-right: 0.15em solid #909090;
                padding-left: 0;
            }
        }
    }

    td,
    th {
        font-feature-settings: "lnum", "tnum";
        font-size: 0.81em;
        line-height: 1.4em;
        margin: 0;
        padding: 0.3em 1em 0.4em 0;
        text-align: left;
        vertical-align: top;

        &.cnt {
            text-align: center;
        }

        &.num {
            text-align: right;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    td {
        padding-left: 1em;
    }

    th {
        border-right: 0.15em solid #909090;
        font-style: normal;
        font-weight: normal;
    }
}

// Comic Jens

#cj {
    p {
        font-size: 0.9em;
        margin-left: 1em;
        max-width: 32em;
    }
}

#lol,
#wtf,
#ftw {
    background: transparent;
    left: 1976px;
    position: absolute;
    top: 0;
    width: 120px;
}

#lol {
    top: 4em;

    p {
        margin: 0;

        a:link {
            padding: 0;
        }
    }
}

#wtf,
#ftw {
    font-size: 1em;
    left: 48em;
    opacity: 0.1;
    position: absolute;
    top: 4em;
    transition: opacity 2s ease;
    width: 30em;
}

#wtf {
    &:hover {
        background: var(--wtf-bg);
        opacity: 0.8;
        transition: opacity 1s ease;
    }
}

#ftw {
    padding: 0;
    top: 4em;

    &:hover {
        opacity: 0.8;
        transition: opacity 1s ease;
    }
}

@media only screen and (max-width: 480px) {
    $line-height: 1.36em;
    $half-line: calc($line-height / 2);

    body {
        font-weight: 450;
        line-height: $line-height;
        padding: 0 0 10em;
        width: 100%;
    }

    body main {
        background: var(--container-bg);
        margin: 0;
        padding: 0.5em 0;

        p,
        ul {
            margin: 0 0.5em $half-line 0.9em;
        }

        ul {
            margin-left: 0;
        }

        li {
            margin: 0 0 $half-line;
        }
    }

    blockquote {
        margin: 1em;
        padding-left: 1.25em;
        padding-right: 1em;
    }

    p,
    ul {
        margin: 0 0.1em $half-line 1em;
    }

    li {
        margin: 0 0 $half-line;
    }

    nav {
        font-size: 1em;
        hyphens: none;
        margin: 0;
        padding-left: 0.35em;

        &.category {
            margin: 0;
            padding-left: 0.5em;
        }
    }

    figure {
        letter-spacing: 0.025em;
        margin: 0 0 1em;
        padding: 0;
        text-align: left;

        &.transparent {
            margin: 0 1em 1em;
        }

        img {
            margin: 0;
            max-width: 100%;
            padding: 0;
        }

        figcaption {
            font-size: 0.81em;
            margin: 0 1.21rem;
        }
    }

    table {
        margin: $line-height 1em;

        td,
        th {
            font-size: 0.81em;
            line-height: $line-height;
            padding: 0 $line-height $half-line 0;
        }
    }

    h1 {
        font-size: 2.9em;
        line-height: 1em;
        margin-left: 0.32em;
    }

    h2 {
        font-size: 2.2em;
        line-height: 1em;
        margin-left: 0.45em;
    }

    h3 {
        font-size: 1.5em;
        line-height: 1em;
        margin-left: 0.6em;
    }

    #lol,
    #wtf,
    #ftw {
        display: none;
    }
}

@media only screen and (max-width: 50em) {
    ul {
        &.narrow {
            columns: 2;
        }
    }
}

@media only screen and (max-width: 38em) {
    ul {
        &.narrow {
            columns: 1;
        }
    }
}

@media only screen and (max-width: 70em) {
    nav {
        &.page-nav {
            float: none;
            margin-left: 3em;
            padding-bottom: 0.1em;
            padding-top: 0.1em;
            position: relative;
        }
    }
}

@media only screen and (max-width: 101em) {
    #wtf,
    #ftw {
        display: none;
    }
}

@media only screen and (max-width: 2100px) {
    #lol {
        display: none;
    }
}
