:root {
    --main-bg-color: 190deg, 87%, 83%;
    --main-text-color: 0deg, 0%, 13%;
    --bg-horizon-color: 0deg, 0%, 38%;
    --bg-var-color: 0deg, 0%, 13%;

    --nav-bg-fallback-color: #0025ca;
    --nav-bg-l-color: oklch(40% 0.245 264);
    --nav-bg-r-color: oklch(69% 0.245 142);
    --nav-link-color: rgba(255, 255, 255, 0.96);
    --nav-link-bg-color: rgba(255, 255, 255, 0.2);
    --nav-link-bg-hover-color: rgba(255, 255, 255, 0.8);
    --nav-link-hover-color: #000;

    --container-bg: rgba(255, 255, 255, 0.5);
    --wtf-bg: rgba(255, 255, 255, 0.85);
    --red-color: rgba(186, 0, 0, 0.65);
    --headline-color: rgba(0, 0, 0, 0.6);
    --tt-color: #004060;
    --link-color: #ba0000;
    --link-color-hover: #de0000;
    --link-color-active: #fff;
    --link-bg-hover: rgba(255, 255, 255, 0.6);
    --figure-bg: rgba(255, 255, 255, 0.8);
    --screenshot-bg: #fff;
}
