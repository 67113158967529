@font-face {
    font-display: swap;
    font-family: MyScreenFont;
    src: url("/assets/fonts/SourceSerif4Variable-Roman.ttf.subset.woff2") format("woff2");
    font-weight: 200 900;
}

@font-face {
    font-display: swap;
    font-family: MyScreenFont;
    src: url("/assets/fonts/SourceSerif4Variable-Italic.ttf.subset.woff2") format("woff2");
    font-style: italic;
    font-weight: 200 900;
}

@font-face {
    font-display: swap;
    font-family: MyMonoFont;
    src: url("/assets/fonts/SudoVar-Regular.woff2") format("woff2");
}
